import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { User } from "../models/user";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  private currentUserSubjectData: BehaviorSubject<User>;

  public currentUser: Observable<User>;
    public currentUserData: Observable<User>;
  public userRole : any
  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();

    this.currentUserSubjectData = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUserData"))
    );
    this.currentUserData = this.currentUserSubjectData.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  public get currentUserDataValue(): User {
    return this.currentUserSubjectData.value;
  }
  login(username: string, password: string) {
    return this.http.post<any>(`${environment.backApiUrl}/Accounts/Login`, {
      uname: username,
      upass: password,
    }).pipe(
      switchMap((user) => {
        if (user.state) {
          return this.http.get(`${environment.backApiUrl}/Calender/GetUesrType/${+user.data[0].emp_code}`).pipe(
            switchMap((response: any) => {
              if (response.state) {
                const userRole = response.data[0].user_type;
                if(userRole == '2'){
                  user.role = "DM";
                } else if(userRole === "1"){
                  user.role = "REP";
                } else {
                  user.role = userRole;
                }
                localStorage.setItem("currentUser", JSON.stringify(user));
                this.currentUserSubject.next(user);
  
                // Fetch user data and store in localStorage
                return this.getUserData(+user.data[0].emp_code).pipe(
                  map((userData) => {
                    return user; // Return user data after storing in localStorage
                  })
                );
              } else {
                console.error("Error:", response.errorMessage);
                return of(false);
              }
            })
          );
        } else {
          return of(user);
        }
      })
    );
  }
  
  
  
  getUserData(emp_code:number){
    return this.http
      .get(`${environment.backApiUrl}/Accounts/GetUserData/${emp_code}`)
      .pipe(
        map((response: any) => {
          if (response.state) {
            localStorage.setItem("currentUserData", JSON.stringify(response.data));
            this.currentUserSubjectData.next(response.data);
            return response.data;
          } else {
            console.error("Error:", response.errorMessage);
            return false;
          }
        })
      );
  }

  getUserTypy(ucode:number){
    return this.http
      .get(`${environment.backApiUrl}/Calender/GetUesrType/${ucode}`)
      .pipe(
        map((response: any) => {
          if (response.state) {
            return response.data;
          } else {
            console.error("Error:", response.errorMessage);
            return false;
          }
        })
      );
  }
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");

    this.currentUserSubject.next(null);
    return of({ success: false });
  }
}
