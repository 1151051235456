import { RouteInfo } from "./sidebar.metadata";
export const ROUTES: RouteInfo[] = [
  {
    path: "",
    title: "MENUITEMS.MAIN.TEXT",
    iconType: "",
    icon: "",
    class: "",
    groupTitle: true,
    badge: "",
    badgeClass: "",
    role: ["All"],
    submenu: [],
  },

  // Admin Modules
  {
    path: "/admin/dashboard/main",
    title: "MENUITEMS.DASHBOARD.TEXT",
    iconType: "material-icons-two-tone",
    icon: "space_dashboard",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin","DM","REP"],
    submenu: [
  
      
    ],
  },

  // Doctor Modules
  {
    path: "/doctor/dashboard",
    title: "MENUITEMS.DASHBOARD.LIST.DOCTOR-DASHBOARD",
    iconType: "material-icons-two-tone",
    icon: "space_dashboard",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Doctor"],
    submenu: [],
  },

  // Patient Modules
  {
    path: "/patient/dashboard",
    title: "MENUITEMS.DASHBOARD.LIST.PATIENT-DASHBOARD",
    iconType: "material-icons-two-tone",
    icon: "space_dashboard",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Patient"],
    submenu: [],
  },
  // Common Modules

  {
    path: "",
    title: "Authentication",
    iconType: "material-icons-two-tone",
    icon: "supervised_user_circle",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/authentication/signin",
        title: "Sign In",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/authentication/signup",
        title: "Sign Up",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/authentication/forgot-password",
        title: "Forgot Password",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/authentication/locked",
        title: "Locked",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/authentication/page404",
        title: "404 - Not Found",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/authentication/page500",
        title: "500 - Server Error",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Extra Pages",
    iconType: "material-icons-two-tone",
    icon: "description",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/extra-pages/blank",
        title: "Blank Page",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Operations",
    iconType: "material-icons-two-tone",
    icon: "description",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin","DM","REP"],
    submenu: [
      {
        path: "/operations/doctors",
        title: "Doctors",
        iconType: "material-icons-two-tone",
        icon: "event_note",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/operations/calls-plan",
        title: "Calls Plan",
        iconType: "material-icons-two-tone",
        icon: "event_note",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/operations/calls",
        title: "Calls",
        iconType: "material-icons-two-tone",
        icon: "event_note",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/operations/non-working-days",
        title: "Non Working Days",
        iconType: "material-icons-two-tone",
        icon: "event_note",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/operations/calls-edite",
        title: "Calls Edite",
        iconType: "material-icons-two-tone",
        icon: "event_note",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/operations/calender",
        title: "Calendar",
        iconType: "material-icons-two-tone",
        icon: "event_note",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Reports",
    iconType: "material-icons-two-tone",
    icon: "report",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin","DM"],
    submenu: [
      {
        path: "/reports/coverage-reports",
        title: "Coverage Reports",
        iconType: "material-icons-two-tone",
        icon: "event_note",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/reports/target-list",
        title: "Target List",
        iconType: "material-icons-two-tone",
        icon: "event_note",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/reports/uncoverd-doctors",
        title: "Uncoverd Doctors",
        iconType: "material-icons-two-tone",
        icon: "event_note",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/reports/unvisitd-calls",
        title: "unvisitd calls",
        iconType: "material-icons-two-tone",
        icon: "event_note",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/reports/calls-report",
        title: "calls report",
        iconType: "material-icons-two-tone",
        icon: "event_note",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },



      {
        path: "/reports/promotion-report",
        title: "Promotion Report",
        iconType: "material-icons-two-tone",
        icon: "event_note",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/reports/calls-detaile",
        title: "Calls Detaile",
        iconType: "material-icons-two-tone",
        icon: "event_note",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/reports/comments-report",
        title: "Comments Report",
        iconType: "material-icons-two-tone",
        icon: "event_note",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/reports/calls-done-by-type",
        title: "Calls Done By Type",
        iconType: "material-icons-two-tone",
        icon: "event_note",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/reports/promotion-report-by-type",
        title: "Promotion Report By Type",
        iconType: "material-icons-two-tone",
        icon: "event_note",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/reports/specilaty-coverage-report",
        title: "Speciality Coverage Reports",
        iconType: "material-icons-two-tone",
        icon: "event_note",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/reports/class-coverage-report",
        title: "Class Coverage Reports",
        iconType: "material-icons-two-tone",
        icon: "event_note",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/reports/class-calls-report",
        title: "Class Calls Reports",
        iconType: "material-icons-two-tone",
        icon: "event_note",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/reports/target-detailes-report",
        title: "Target Detail Reports",
        iconType: "material-icons-two-tone",
        icon: "event_note",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/reports/specilaty-calls-report",
        title: "Speciality Calls Reports",
        iconType: "material-icons-two-tone",
        icon: "event_note",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Multi level Menu",
    iconType: "material-icons-two-tone",
    icon: "slideshow",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/multilevel/first1",
        title: "First",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/",
        title: "Second",
        iconType: "",
        icon: "",
        class: "ml-sub-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [
          {
            path: "/multilevel/secondlevel/second1",
            title: "Second 1",
            iconType: "",
            icon: "",
            class: "ml-menu2",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: [""],
            submenu: [],
          },
          {
            path: "/",
            title: "Second 2",
            iconType: "",
            icon: "",
            class: "ml-sub-menu2",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: [""],
            submenu: [
              {
                path: "/multilevel/thirdlevel/third1",
                title: "third 1",
                iconType: "",
                icon: "",
                class: "ml-menu3",
                groupTitle: false,
                badge: "",
                badgeClass: "",
                role: [""],
                submenu: [],
              },
            ],
          },
        ],
      },
      {
        path: "/multilevel/first3",
        title: "Third",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
];
